import { Controller } from '@hotwired/stimulus'
import consumer from 'channels/consumer'

export default class extends Controller {
  connect () {
    const reference = this.element.dataset.reference
    if (reference) {
      this.channel = consumer.subscriptions.create(
        {
          channel: 'PaymentStepDataReceivedChannel',
          reference: reference
        },
        {
          received: this.process
        }
      )
    }
  }

  process () {
    window.location.reload()
  }
}
