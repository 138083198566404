import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get outlets () {
    return ['summary']
  }

  get checkedOptions () {
    return this.element.querySelectorAll('input:checked')
  }

  updateSummary () {
    if (this.hasSummaryOutlet) {
      this.summaryOutlet.updateSummary(this.checkedOptions)
    }
  }
}
