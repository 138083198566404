import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['option']
  }

  updateSummary (checkedOptions) {
    this.unselectAll()

    checkedOptions.forEach((checkedOption) => {
      this.optionTargets
        .filter((option) => option.dataset.choiceId === checkedOption.value)
        .forEach((option) => { option.checked = true })
    })
  }

  unselectAll () {
    this.optionTargets.forEach((option) => { option.checked = false })
  }
}
